 
  html, body {
    margin: 0; /* Remove default margins */
    padding: 0; /* Remove default padding */
    width: 100%; /* Full width */
    height: 100vh; /* Full viewport height */
    overflow-x: hidden; /* Prevent horizontal scroll */
    /* display: flex; Flexbox for centering */
    justify-content:center; /* Center horizontally */
    background-color: white; /* Optional: background color */
   
  }
  .container {
    width: 100%; /* Full width */
    background-color: white; 
    overflow: hidden; /* Allow vertical scrolling if needed */
    flex-direction: column;
    display: flex;
  }
  .maintitle{
    width: 75%;
    height: 'auto';
  
    padding-left: 10%;
    padding-right: 10%;
  }
  .maintitle2{
    width: 65%;
    height: 'auto';
    padding-left: 10%;
    padding-right: 10%;
  }
.toplococontainer{
  width: 100%;
  background-color: black ;
  align-items: center;
  display: flex;
  justify-content: center;


}
  .toplogo{ 
    width: 150px;
    padding-bottom: 16px;
    padding-top: 12px;
    padding-left: 10%;
    padding-right: 10%;
    height: auto;
  }

.maintitlecontainer{
  align-items: center;
  display: flex;
  padding-top:10%;
  justify-content: center;
}

  .horizontal-group {
    display:flex; /* Enable flexbox */
    justify-content:space-around; /* Space between elements */
    gap: 10px; /* Optional: space between items */
    overflow-x: hidden; /* Prevent horizontal scroll */
    padding-top:10%;
    background-color: white; /* Optional: background color */
    padding-left: 15%;
    padding-right: 15%;
  }

 
  .gendertxt{
    width: 75%;
    height: 'auto' ; 

  }
  .gendericon{
    width: 50% ;
    height: 'auto' ; 
    padding-bottom: 24px;
  }
  .rounded-shadow-border {
    width: 25%;              /* Width of the div */
    height: 25%;             /* Height of the div */
    margin-top: 10% ;
    margin-bottom: 20px;
     justify-content: space-between; /* Space between elements */
    background-color: white;   /* Background color */
    border: 3px solid #3498db; /* Stroke (border) with color */
    align-items: center; 
    border-radius: 15px;       /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Shadow */
    padding: 20px;             /* Padding inside the div */
    text-align: center;        /* Centered text */
              /* Margin outside the div */
} 

.lock-group {
  width: 90%;
  display: grid; /* Enable flexbox */
  column-gap: 6px;
  row-gap: 4px;
  grid-template-columns: repeat(auto-fit, minmax(72px, 1fr));
  background-color: white; /* Optional: background color */
  
  padding: 5%;
}
.lock-item-free {
   width: 72px;
   height:56px;
   background: rgb(2, 233, 117);
   align-content: center;
   justify-content: center;
   text-align: center;
   border: 2px solid black; 
   display: flex;
   align-items: center;
   display: flex;
   font-size: 100%;
   font-weight: 500;
}
 
.lock-item-use {
  width: 72px;
  height:56px;
  background: #fe8787;
  align-content: center;
  justify-content: center;
  text-align: center; 
  border: 2px solid black; 
  align-items: center;
  display: flex;
  font-size: 100%;
  font-weight: 500;
}


.textlayout{
  align-items: center;
  padding-top: 5%;
  justify-content: space-around;
  display: flex;
  padding-left: 5%;
  padding-right: 5%;
}
.totaltxt{
   font-size: 100%;
   font-weight: 500;
   color: black;
}
.usingtxt{
  font-size: 100%;
  font-weight: 500;
  color: rgb(255, 6, 6);
}
.lefttxt{
  font-size: 100%;
  font-weight: 500;
  color: rgb(2, 233, 117);
}

.floatingbutton{ 
  width: 32px;
}
.floatingbuttoncontainer{
  width: 56px;
  height: 56px;
  bottom: 2.5%;
  right: 5%;
  position: fixed;     
  background-color: black;
  border-radius: 28px; /* 둥근 모서리 설정 */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* 그림자 설정 */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}